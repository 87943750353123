<template>
  <tr>
    <td style="white-space: nowrap;">
      <fa
        v-if="canDelete"
        icon="trash"
        class="text-danger pointer mr-1"
        @click="deleteChild(item.id)">
      </fa>
      {{item.code}}
    </td>
    <td class="text-center">
      <div>
        <small>
          <dl class="row my-0">
            <dd class="col-6 text-right mb-0" v-if="item.closedAt">
              จัดส่งแล้ว:
            </dd>
            <dd
              style="white-space: pre-line;"
              class="col-6 text-left text-success mb-0"
              v-if="item.closedAt">
              {{item.closedAt | date}}
            </dd>

            <dd class="col-6 text-right mb-0" v-if="item.billedAt">
              ครบกำหนด:
            </dd>
            <dd
              style="white-space: pre-line;"
              class="col-6 text-left text-danger mb-0"
              v-if="item.billedAt">
              {{item.billedAt | date}}
            </dd>
          </dl>
        </small>
      </div>
    </td>
    <td class="text-right" style="white-space: nowrap;">
      <span class="text-success">
        {{item.totalPrice | comma}}
      </span>
       บาท
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    canDelete: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    deleteChild (id) {
      this.$emit('delete', id)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
