<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>

    <hr class="my-3">
    <div v-if="isLoaded">
      <sgv-form
        :method.sync="method"
        :options="options">

        <DetailForm
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :formData="formData"
          :method="method"
          :v="$v"
          @updated="dataDetail"
          @contact="contact = $event">
        </DetailForm>

        <DetailApproveConfirmation
          slot="approve"
          ref="approve"
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          @update="method = 'info'">
        </DetailApproveConfirmation>

        <DetailCloseConfirmation
          slot="close"
          ref="close"
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          @update="method = 'info'">
        </DetailCloseConfirmation>
      </sgv-form>

      <template v-if="docId > 0 && formData.contactId">
        <HrDivider :options="tabs" v-model="selectedTab"></HrDivider>

        <DetailOrder
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          v-if="'DetailOrder' === selectedTab && docId > 0"
          :method="method"
          :formData="formData"
          @updated="dataDetail">
        </DetailOrder>

        <Attachment
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          v-if="'Attachment' === selectedTab && docId > 0"
          :method="method"
          :formData="formData">
        </Attachment>

        <Address
          printBranch
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          v-if="'Address' === selectedTab && docId > 0">
        </Address>
      </template>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import DetailForm from './DetailForm'
import DetailApproveConfirmation from './DetailApproveConfirmation.vue'
import DetailCloseConfirmation from './DetailCloseConfirmation.vue'
import DetailOrder from './DetailOrder.vue'
import Attachment from '@/views/doc_core/components/Attachment'
import Address from '@/views/doc_core/components/Address'
import {
  DETAIL_DOC, WATCH_DOC_UPDATED,
  CREATE_DOC, UPDATE_DOC, DESTROY_DOC,
  CANCEL_DOC, ROLLBACK_DOC,
} from './graph'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.formData.code}` || `${this.title}`
    }
  },
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      method: 'info',
      listView: `Doc${this.$form.capitalize(this.docType)}List`,
      formData: {
        type: '',
        code: '',
        name: '',
        contactId: this.$route.query.contactId,
        remark: '',
        billedAt: '',
        createdAt: null,
        createdUser: null,
        approvedAt: null,
        approvedUser: null,
        closedAt: null,
        closedUser: null,
        junctionChildren: [],
        categories: []
      },
      selectedTab: 'DetailOrder',
      isLoaded: false,
      contact: null,
    }
  },
  validations: {
    formData: {
      contactId: { required },
      billedAt: { required }
    }
  },
  apollo: {
    $subscribe: {
      docUpdated: {
        query () {
          return WATCH_DOC_UPDATED(this.templateType)
        },
        variables () {
          return {
            docType: this.docType,
            docId: this.docId
          }
        },
        result (res) {
          this.setFormData(res.data.docUpdated)
        },
      },
    },
  },
  computed: {
    tabs () {
      let arr = [
        {text: 'รายการ', value: 'DetailOrder'},
        {text: 'ติดต่อ', value: 'Address'},
        {text: 'ไฟล์', value: 'Attachment'},
      ]
      return arr
    },
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || this.formData.approvedAt || !this.$auth.hasRole(`doc:${this.docType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || this.formData.approvedAt || !this.$auth.hasRole(`doc:${this.docType}:add`)
        },
        {
          text: 'อนุมัติ',
          variant: 'warning',
          method: 'approve',
          func: this.approveData,
          disabled: this.method === 'add' || this.formData.approvedAt || !this.$auth.hasRole(`doc:${this.docType}:approve`)
        },
        {
          text: 'ยกเลิก',
          variant: 'info',
          method: 'cancel',
          func: this.cancelData,
          disabled: this.method === 'add' || !(this.formData.approvedAt && !this.formData.closedAt) || !this.$auth.hasRole(`doc:${this.docType}:approve`)
        },
        {
          text: 'จบ',
          variant: 'success',
          method: 'close',
          func: this.closeData,
          disabled: this.method === 'add' || !(this.formData.approvedAt && !this.formData.closedAt) || !this.$auth.hasRole(`doc:${this.docType}:close`)
        },
        {
          text: 'ย้อนกลับ',
          variant: 'info',
          method: 'rollback',
          func: this.rollbackData,
          disabled: this.method === 'add' || !this.formData.closedAt || !this.$auth.hasRole(`doc:${this.docType}:close`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.setFormData(res.data.doc)
      })
      .catch(this.$toasted.global.error)
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (key === 'categories') {
          this.formData[key] = v[key].map(x => x.id)
          return
        } else if (key === 'billedAt') {
          this.formData[key] = this.$date.format(v[key]).date
        } else {
          this.formData[key] = v[key]
        }
      })
    },
    serializeInput (v) {
      const input = {...v}
      const removeKeys = [
        'name', 'type', 'createdAt', 'approvedAt', 'closedAt',
        'createdUser', 'approvedUser', 'closedUser', 'junctionChildren'
      ]

      if (input.billedAt) {
        input.billedAt = this.$date.format(input.billedAt).utc
      } else {
        input.billedAt = null
      }

      removeKeys.forEach(x => delete input[x])
      return input
    },
    createData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, docId: res.data.doc.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input
        }
      })
      .then(() => {
        this.method = 'info'
        if (this.selectedTab === 'DetailKpi') {
          this.$refs.kpi.refetch()
        }
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    approveData () {
      this.$refs.approve.approveData()
    },
    cancelData () {
      this.$apollo.mutate({
        mutation: CANCEL_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("ยกเลิก")
      })
      .catch(this.$toasted.global.error)
    },
    closeData () {
      this.$refs.close.closeData()
    },
    rollbackData () {
      this.$apollo.mutate({
        mutation: ROLLBACK_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("ย้อนรายการสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  created () {
    if (this.docId === 0) {
      this.method = 'add'
      this.isLoaded = true
      if (this.$route.query.contactId) {
        this.formData.contactId = this.$route.query.contactId
      }
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailForm,
    Attachment,
    DetailApproveConfirmation,
    DetailCloseConfirmation,
    DetailOrder,
    Address
  }
}
</script>

<style lang="css">
</style>
