<template>
  <div class="">
    <div class="row">
      <div
        class="col-12 col-md-6 my-2"
        v-for="item in items"
        :key="item.id">
        <DetailOrderCreditNoteAvailableCard
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :item="item"
          @updated="emitUpdated">
        </DetailOrderCreditNoteAvailableCard>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { LIST_ORDER_AVAILABLE_CREDIT_NOTE } from './graph'
import DetailOrderCreditNoteAvailableCard from './DetailOrderCreditNoteAvailableCard.vue'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      items: [],
      search: ''
    }
  },
  methods: {
    getList: debounce(function (filter) {
      this.$apollo.query({
        query: LIST_ORDER_AVAILABLE_CREDIT_NOTE(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          q: filter
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.items = res.data.docs
      })
      .catch(() => {
        this.items = []
      })
    }, 150),
    refetchData () {
      if (this.search) {
        this.getList({
          limit: 10,
          params: {
            search: this.search
          }
        })
      } else {
        this.getList({
          limit: 10
        })
      }
    },
    emitUpdated () {
      this.refetchData()
      this.$emit('updated', null)
    }
  },
  watch: {
    search: {
      handler () {
        this.refetchData()
      },
      immediate: true
    },
    items (value) {
      this.$emit('isItems', value.length > 0)
    }
  },
  components: {
    DetailOrderCreditNoteAvailableCard
  }
}
</script>

<style lang="css" scoped>
</style>
